<script>
import {Button} from "vant";

export default {
  name: 'Sign',
  components: {Button},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    clearSign() {
      this.$refs.signaturePad.clearSignature()
    },
    quit(){
      this.$emit("quit")
      this.$refs.signaturePad.clearSignature()
    },
    exportImg() {
      this.$emit('exportImg', this.$refs.signaturePad.saveSignature())
    }
  }
}
</script>
<template>
  <div class="wrapper">
    <div class="hand-center">
      <VueSignaturePad class="signature-pad" ref="signaturePad"/>
    </div>
    <div class="buttons">
      <Button type="info" size="small" @click.stop="quit">取 消</Button>
      <Button type="info" size="small" @click.stop="clearSign">重 写</Button>
      <Button type="info" size="small" @click.stop="exportImg">完 成</Button>
    </div>
  </div>
</template>
<style lang="less" scoped>
.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  flex-direction: column;
}

.hand-center {
  flex: 1;
  margin: 10px;
  border: 4px dashed #e9e9e9;
  overflow: hidden;
  box-sizing: border-box;
}

.hand-writing {
  background: #fff;
  width: 100%;
  height: 95vh;
}

.buttons {
  position: absolute;
  transform: rotate(90deg);
  bottom: 3rem;
  left: -0.8rem;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin: 0 5px;
  }
}
</style>