<script>
const TypeTitle = {
  default: '',
  type: [Number, String]
}
export default {
  name: "InfoLabel",
  props: {
    title: TypeTitle,
    content: TypeTitle
  },
}
</script>

<template>
  <div class="box">
    <span class="title">{{title}}</span>
    <span class="content">{{content}}</span>
  </div>
</template>

<style scoped lang="less">
@media (min-width: 980px) {

}
.box{
  padding: 0.05rem 0.5rem;
  display: flex;
  .title{
    color: #9A9A9A;
    width: 2rem;
    margin-right: 0.04rem;
  }
  .content{
    flex: 1;
    text-align: end;
  }
}
</style>