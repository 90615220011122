<script>
import 'lib-flexible/flexible.js'
import Card from "@/views/RemoteSign/components/Card.vue";
import GoodLabel from "@/views/RemoteSign/components/GoodLabel.vue";
import copy from "copy-to-clipboard";
import {Dialog, Toast} from "vant";
import request from "@/utils/request";
import {dataURLtoFile, parseFloatBySystem, upload} from "@/utils/utils";
import math from "../../utils/math";
import SignOverlay from "@/views/RemoteSign/SignOverlay.vue";
import SignModel from "@/views/RemoteSign/components/SignModel.vue";
import InfoLabel from "@/views/RemoteSign/components/InfoLabel.vue";
import AttachmentList from "@/components/attachmentList.vue";
import SingleCard from "@/views/RemoteSign/SingleCard.vue";
import enums from "@/utils/enums";
import {Send} from "@icon-park/vue"
export default {
  name: "SignView",
  components: {
    SingleCard,
    AttachmentList,
    InfoLabel,
    SignModel,
    SignOverlay,
    GoodLabel,
    Card,
    Send,
  },
  data() {
    return {
      enums,
      text: `请点击下方链接，对售后服务签字确认 `,
      id: null,
      type: null,
      signImg: null,
      customerName: "客户",
      enableSign:enums.BOOL.YES.v,
      loading:false,
      data: {
        goods_record: [],
        service_record: [],
        order: {}
      },
      showSign: true,
    }
  },
  methods: {
    getTotalMoney(list) {
      return parseFloatBySystem(list
          .reduce((a, b) => ({total_money: math.add(a.total_money || 0, b.total_money || 0)}), {})
          .total_money,8)
    },
    parseFloatBySystem,
    setSignImg(src) {
      this.signImg = src
    },
    initData(id) {
      this.loading = true
      request({
        url: '/single/new_work_order_finish_detail',
        data: {id}
      }).then(res => {
        this.data = res.data.data
        this.enableSign = this.getOrder.sign?enums.BOOL.YES.v:enums.BOOL.NO.v
        // 已归档已取消无法进行签字
        if([60,70].includes(this.getOrder.status)){
          this.enableSign = enums.BOOL.YES.v
        }
        this.signImg = this.getOrder.sign
      }).catch(() => this.$router.push({name: '404'}))
        .finally(()=>{
            this.loading=false
        })
    },
    copy(text=this.text) {
      copy(text + this.url)
      Dialog({
        title: '已复制成功',
        message: `快去粘贴给好友吧!`,
        confirmButtonColor: '#1f1f1f'
      })
    },
    toast(message){
      Toast({
        message,
        position:'bottom'
      })
    },
    overShare(){
      const {url} = this
      if (window?.navigator?.share) {
        window.navigator.share({
          url,
          text:"请点击下方链接，查看售后服务信息"
        }).catch(() => {
          this.copy('请查看售后服务信息。')
        })
      } else {
        this.copy('请查看售后服务信息。')
      }

    },
    share() {
      if (this.type === '2') {
        if(!this.signImg){
          this.toast('请填写签字')
          return
        }
        this.loading = true
        const time = `${new Date().getTime()}`
        const fileName = `${time}_${this.id}_${encodeURIComponent(this.customerName)}.png`
        const file = dataURLtoFile(this.signImg, fileName)
        upload(file).then(res => {
          const customer_sign = res.data?.data?.url
          request({
            url: '/single/new_work_order_remote_sign',
            data: {
              customer_sign,
              new_work_order_id: this.id
            }
          }).then(({data})=>{
            this.toast(data.success?'提交成功':data.msg)
            if(data.success){
              this.initData(this.id)
            }
          }).catch(({data})=>{
            this.toast(data.msg)
          }).finally(()=>{
            this.loading=false
          })
        })
        return
      }
      const {url} = this
      if (window?.navigator?.share) {
        window.navigator.share({
          url,
          text:"请对售后服务签字确认"
        }).catch(() => {
          this.copy()
        })
      } else {
        this.copy()
      }

    },
    openSign(ispc) {
      if(this.enableSign===enums.BOOL.YES.v){
        return
      }
      if(ispc===true){
        this.$refs.signModel.open()
        return
      }
      this.showSign = false
      this.$refs.signOverlay.open()
    },
    closeSign() {
      this.showSign = true
    }
  },
  computed: {
    showT(){
      return [
          this.data.goods_record.length,
          this.data.service_record.length,
          this.data.order.off_price,
          this.data.order.money
      ].some(d=>d)
    },
    getOrder() {
      return this.data?.order || {}
    },
    getWorkRelationList() {
      return this.getOrder?.work_relation_list || []
    },
    url() {
      return location.origin + `/#/sign/${this.id}/2/?c_n=${encodeURIComponent(this.customerName)}`
    },
    math() {
      return math
    },
    btnTxt() {
      return this.type === '1' ? `分享给${this.customerName}签字` : '信息确认无误 提交签字'
    }
  },
  created() {
    this.customerName = this.$route.query.c_n || "客户"
    const {id, type} = this.$route.params || {}
    this.id = id
    this.type = type
    this.initData(this.id)
  }
}
</script>

<template>
  <a-spin :spinning="loading">
    <div class="card-content">
    <div class="left-content">
      <div class="left-scroll" >
        <Card title="报修信息" :type="3" class="body-info-list" :show-bottom="false">
          <template slot="content">
            <InfoLabel v-if="getOrder.customer" :title="customerName" :content="getOrder.customer.name||'无'"/>
            <InfoLabel title="电话" :content="getOrder.linkman_mobile||'无'"/>
            <InfoLabel :title="customerName+'地址'" :content="getOrder.full_address||'无'"/>
            <div class="bottom-border-gary-1"></div>
            <SingleCard v-for="(single,index) in getWorkRelationList" :key="index" :single="single"></SingleCard>
            <div class="bottom-border-gary-1" v-if="getWorkRelationList.length"></div>
            <InfoLabel title="故障描述" :content="data.order.content||'无'"/>
            <InfoLabel v-if="data.order.attachments" title="附件" :content="data.order.attachments.length?'':'无'"/>
            <attachment-list
                class="attachment-list"
                v-if="data.order.attachments&&data.order.attachments.length"
                :can-upload="false"
                :value="data.order.attachments"></attachment-list>
            <InfoLabel class="info-bottom" title="处理结果" :content="getOrder.finish_content||'无'"/>
          </template>
        </Card>
        <Card title="使用备件" :type="2" class="goods-list" v-if="data.goods_record.length>0">
          <template slot="content">
            <GoodLabel v-for="(goods,index) in data.goods_record"
                       :index="index"
                       :key="index"
                       :title="goods.goods_name"
                       :total_money="parseFloatBySystem(goods.total_money,8)"
                       :num="parseFloatBySystem(goods.number,8)"
                       :price="parseFloatBySystem(goods.sale_price,8)"
                       :code="goods.goods.code ? goods.goods.code: ''"
                       :standard="goods.goods.standard ? goods.goods.standard: '' "/>
          </template>
          <template slot="bottom">
            备件小计
            <span class=total-money>
              ¥{{ getTotalMoney(data.goods_record) }}
            </span>
          </template>
        </Card>
        <Card title="使用服务" :type="4" class="server-list" v-if="data.service_record.length>0">
          <template slot="content">
            <GoodLabel v-for="(service,index) in data.service_record"
                       :style="{}"
                       :index="index"
                       :key="index"
                       :is-show-info="false"
                       :title="service.service.name"
                       :total_money="parseFloatBySystem(service.total_money,8)"
                       :num="parseFloatBySystem(service.number,8)"
                       :price="parseFloatBySystem(service.sale_price,8)"/>
          </template>
          <template slot="bottom">
            服务小计
            <span class=total-money>
              ¥{{ getTotalMoney(data.service_record) }}
            </span>
          </template>
        </Card>
        <Card v-if="showT" title="费用汇总" :type="1">
          <template slot="content">
            <div class="all-card" v-if="data.goods_record.length>0">
              <div>备件费用</div>
              <div class="money">
                ¥{{ getTotalMoney(data.goods_record) }}
              </div>
            </div>
            <div class="all-card" v-if="data.service_record.length>0">
              <div>服务费用</div>
              <div class="money">
                ¥{{ getTotalMoney(data.service_record) }}
              </div>
            </div>
            <div class="all-card">
              <div>折扣费用</div>
              <div class="money">￥{{ parseFloatBySystem(data.order.off_price||0,8) }}</div>
            </div>
          </template>
          <template slot="bottom">
            合计费用
            <span class="money total-money" style="color: #FD8A25;">￥{{ parseFloatBySystem(data.order.money,8)||0 }}</span>
          </template>
        </Card>
        <Card class="sign-handles" v-if="type==='2'||this.signImg" :title="customerName+`签字`" :show-bottom="false">
          <template slot="content">
            <div class="sign-name" @click="openSign">
              <img style="transform: rotate(-90deg);width: 4rem;" :src="signImg" alt="signImg" v-if="signImg">
              <span v-else>点击此处签字</span>
            </div>
          </template>
        </Card>
        <Card class="sign-handles-pc" v-if="type==='2'||this.signImg" :title="customerName+`签字`" :show-bottom="false">
          <template slot="content">
            <div class="sign-name" @click="openSign(true)">
              <img style="transform: rotate(-90deg);width: 140px;" :src="signImg" alt="signImg" v-if="signImg">
              <span v-else>点击此处签字</span>
            </div>
          </template>
        </Card>
      </div>
      <a-button v-if="enableSign===enums.BOOL.NO.v||this.type==='1'&&(!this.signImg||this.type==='2')"
                @click="share" class="sing-button-pc" type="primary">
        <Send v-if="this.type==='1'" size="16" style="margin-right: 5px;position: relative;top: 3px"/>{{ btnTxt }}
      </a-button>
      <div v-else-if="type!=='2'" class="sing-button-pc sing-button-pc-over">
        <a-button type="primary" style="margin-left: 0;width: 100px;" @click="overShare">分享</a-button>
        <a-button disabled style="flex: 1">{{customerName}}已签字</a-button>
      </div>
      <div class="left-bottom-button" v-if="showSign&&enableSign===enums.BOOL.NO.v||this.type==='1'">
        <div class="left-bottom-button-handle" @click="share" v-if="!this.signImg||this.type==='2'">
          <Send v-if="this.type==='1'" size="0.4rem" style="margin-right: 0.1rem;position: relative;top: 0.03rem"/>
          <span>{{ btnTxt }}</span>
        </div>
        <div v-else class="over-bottom">
          <div class="over-share" style="width: 3rem;" @click="overShare">分享</div>
          <div class="over-sign">{{customerName}}已签字</div>
        </div>
      </div>
    </div>
    <SignModel ref="signModel" @signImg="setSignImg"/>
    <SignOverlay ref="signOverlay" @signImg="setSignImg" @close="closeSign"/>
  </div>
  </a-spin>
</template>

<style scoped lang="less">
.card-content {
  padding: 0.2rem;
  background: #f1f1f1;

  /deep/ .van-cell {
    border-radius: 0.2rem;
    padding: 0;
    //padding-bottom: 0.1rem;
    margin-bottom: 0.4rem;
  }
}

.sign-handles-pc {
  display: none;
}

.left-content {
  position: relative;
}

.sing-button-pc {
  display: none;
}

.left-scroll {
  overflow-y: auto;
  padding-bottom: 2.2rem;
}

.left-bottom-button {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  height: 2.2rem;
  z-index: 999;

  .left-bottom-button-handle {
    background: #00A578;
    color: #fff;
    text-align: center;
    font-size: 0.4rem;
    padding: 0.3rem;
    margin: 0.3rem;
    border-radius: 0.2rem;
  }
  .over-bottom{
    display: flex;
    height: 100%;
    line-height: 0.78rem;
    .over-share{
      background: #00A578;
      color: #fff;
      text-align: center;
      font-size: 0.4rem;
      padding: 0.2rem;
      margin: 0.5rem 0.2rem;
      border-radius: 0.2rem;
    }
    .over-sign{
      .over-share();
      flex: 1;
      color: #9c9c9c;
      background: #f0f0f0;
    }
  }
}

.bal {
  font-weight: bold;
  font-size: 0.44rem;
  margin-left: 0.1rem;
}

.sign-name {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-card {
  display: flex;
  padding: 0.05rem 0.5rem;
  justify-content: space-between;
}

.attachment-list {
  padding: 0 0.5rem;
  margin-bottom: 0.2rem;
}

.bottom-border-gary-1 {
  width: 90%;
  border-bottom: 0.5px solid #E8E8E8;
  margin: 0.1rem auto;
}
.info-bottom{
  margin-bottom: 0.2rem;
}
.total-money{
  font-size: 0.44rem;
  line-height: 0.44rem;
}
@media (min-width: 980px) {
  .total-money{
    font-size: 18px;
    line-height: 18px;
  }
  .info-bottom{
    margin-bottom: 10px;
  }
  .attachment-list {
    padding: 0 20px;
    margin-bottom: 7px;
  }

  .sing-button-pc {
    display: block;
    width: 400px;
    //position: fixed;
    //bottom: 20px;
  }
  .sing-button-pc-over{
    display: flex;
    &>*{
      margin: 0 5px;
    }
  }

  .left-scroll {
    padding-bottom: 20px;
  }

  /deep/ header {
    padding: 10px 10px 10px 16px;

    .title {
      font-size: 16px;
    }
  }

  /deep/ .goods-label {
    padding: 8px 20px;
    font-size: 15px;
    margin-bottom: 4.4px;
  }

  /deep/ .goods-label .info {
    margin-top: 4px;
    font-size: 12px;
    line-height: 12px;
  }

  /deep/ .ant-table-content {
    background: #fff;
  }

  /deep/ .sign-handles-pc {
    cursor: pointer;
    display: block;
  }

  .left-bottom-button, .sign-handles {
    display: none;
  }

  /deep/ .sign-handles-pc {
    .sign-name {
      height: 140px;
    }
  }

  .table-total {
    text-align: end;
    font-size: 15px;
    margin: 10px 0;
  }

  /deep/ .card-bottom {
    height: 45px;
    line-height: 45px;
    padding-right: 20px;
    font-size: 14px;
  }

  .card-content {
    /deep/ .van-cell {
      margin-bottom: 10px;
    }

    margin: 0 auto;
    width: 980px;
    display: flex;
    justify-content: center;

    .left-content {
      width: 400px;
    }
  }
}
/deep/ .ant-spin-dot-spin{
  position: fixed !important;
}
</style>