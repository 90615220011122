<script>
import {Cell} from "vant";
import {Finance,DatabaseSetting,Spanner,RightUser} from "@icon-park/vue"
export default {
  name: "Card",
  components: {Cell,Finance,DatabaseSetting,Spanner,RightUser},
  props: {
    title: {
      type: [String, Number],
      default: '标题'
    },
    showBottom: {
      type: Boolean,
      default: true
    },
    type:{
      type: Number,
      default: 0
    }
  },
}
</script>

<template>
  <Cell class="cell">
    <header>
      <div class="title">
        <Finance v-if="type===1" fill="#00A578" theme="filled" class="icon"/>
        <DatabaseSetting v-if="type===2" fill="#00A578" theme="filled" class="icon"/>
        <Spanner v-if="type===3" fill="#00A578" theme="filled" class="icon"/>
        <RightUser v-if="type===4" fill="#00A578" theme="filled" class="icon"/>
        {{ title }}
      </div>
    </header>
    <slot name="content"></slot>
    <div class="bottom-border-gary-1" v-if="showBottom"></div>
    <div class="card-bottom" v-if="showBottom">
      <slot name="bottom"></slot>
    </div>
  </Cell>
</template>

<style scoped lang="less">
header {
  padding: 0.33rem;
  //border-bottom: solid 1px #f1f1f1;
  display: flex;
  align-items: center;
  margin-bottom: 0.10rem;

  .title {
    font-weight: 500;
    color: #00a578;
    font-size: 0.45rem;
    line-height: 0.45rem;
  }
}
.bottom-border-gary-1 {
  width: 90%;
  border-bottom: 0.5px solid #E8E8E8;
  margin: 0.1rem auto;
}
.card-bottom {
  text-align: end;
  height: 1rem;
  line-height: 1rem;
  padding-right: 0.5rem;
  font-size: 0.37rem;
}
.icon{
  position: relative;
  top: 0.06rem;
  font-size: 0.5rem;
}
@media (min-width: 980px) {
  .icon{
    top: 5px;
    font-size: 20px;
  }
}
</style>