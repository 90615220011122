<script>
const typeF = {
  type: [Number, String],
  default: ''
}
export default {
  name: "GoodLabel",
  props: {
    isShowInfo: {
      type: Boolean,
      default: true
    },
    index:typeF,
    price:typeF,
    title: typeF,
    info: typeF,
    standard: typeF,
    code: typeF,
    num: typeF,
    total_money:typeF,
  }
}
</script>

<template>
  <div class="goods-label">
    <div class="left label">
      <div class="title">{{ title }}</div>
      <div class="info" v-if="isShowInfo">规格: {{ standard || '无' }}</div>
      <div class="info" v-if="isShowInfo">编号: {{ code || '无' }}</div>
    </div>
    <div class="right right-label">
      <div>¥{{price}}</div>
      <div class="right-label-number">数量 X{{num}}</div>
<!--      <div> {{num}} x {{price}} = ¥{{total_money}} </div>-->
    </div>
  </div>
</template>

<style scoped lang="less">
.gary-small {
  color: #a1a1a1;
}

.goods-label {
  position: relative;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.06rem;
  font-size: 0.4rem;
  display: flex;
  justify-content: space-between;
  .num, .info {
    .gary-small();
    margin-top: 0.1rem;
    font-size: 0.33rem;
    line-height: 0.33rem;
  }

  .label {
    flex-direction: column;
    display: flex;
  }
  .right-label{
    .label();
    text-align: end;
    justify-content: flex-end;
  }

  .right {
    flex: none;
    margin-left: 0.1rem;
  }

  .num, .price {
    text-align: end;
  }
}
.right-label-number{
  color: #9A9A9A;
  font-size: 0.3rem;
  line-height: 0.3rem;
}
@media (min-width: 980px) {
  .right-label-number{
    color: #9A9A9A;
    font-size: 12px;
    line-height: 12px;
  }
}
</style>