<script>
import {Tag} from "vant"
import enums from "@/utils/enums";
export default {
  name: "SingleCard",
  components:{Tag},
  props:{
    single:{
      type:Object,
      default(){
        return {}
      },
    }
  },
  computed:{
    enums() {
      return enums
    },
    getUrl(){
      return this.single?.single?.avatars?.length ?this.single.single.avatars[0]:null
    }
  }
}
</script>

<template>
  <div class="content">
    <div >
      <div class="title" v-if="single.goods.name">
        {{single.goods.name}}
        <Tag color="#cdeae4" v-if="single.in_guarantee===enums.BOOL.YES.v" text-color="#00a578">保内</Tag>
        <Tag color="#ffa4a0" v-if="single.in_guarantee===enums.BOOL.NO.v" text-color="#ff2f3e">保外</Tag>
      </div>
      <div class="info" v-if="single.goods.standard">规格 {{single.goods.standard}}</div>
      <div class="info" v-if="single.single&&single.single.code">编号 {{single.single.code}}</div>
    </div>
    <img class="goods-img" v-if="getUrl" :src="getUrl" alt="商品图片">
  </div>
</template>

<style scoped lang="less">
.content{
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.4rem;
}
.goods-img{
  object-fit: contain;
  width: 2rem;
  height: 100%;
}
.title{
  color: #000;
}
.info{
  padding-left: 0.05rem;
  color: #9A9A9A;
}
@media (min-width: 980px) {
  .content{
    padding: 10.8px 21.6px;
  }
  .goods-img{
    object-fit: contain;
    width: 80px;
    height: 100%;
  }
}
</style>