<script>
import {rotateBase64Img} from "@/utils/utils";
export default {
  name: "SignModel",
  components: {},
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open(){
      this.visible = true
    },
    save() {
      if(this.$refs.signaturePad&&!this.$refs.signaturePad.isEmpty()){
        rotateBase64Img(this.$refs.signaturePad.saveSignature().data,90,(data)=>{
          this.$emit('signImg',(data))
        })
        this.cancel()
      }
    },
    clearCanvas(){
      if(this.$refs.signaturePad) this.$refs.signaturePad.clearSignature()
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>

<template>
  <a-modal
      title="签字"
      :width="740"
      destroyOnClose
      :visible="visible"
      @ok="save"
      @cancel="cancel"
  >
    <template slot="footer">
      <a-button  @click="cancel">
        取消
      </a-button>
      <a-button  @click="clearCanvas">
        清除
      </a-button>
      <a-button type="primary" @click="save">
        完成
      </a-button>
    </template>
    <div class="sign-view">
      <VueSignaturePad v-if="visible" class="signature-pad" ref="signaturePad"/>
    </div>
  </a-modal>
</template>

<style scoped lang="less">
/deep/ .ant-modal-body{
  padding: 10px;
}
/deep/ .ant-modal-footer{
  padding: 10px;
}
/deep/ .ant-modal-header{
  padding: 10px;
}
/deep/.ant-modal-close-x{
  height: 43px;
  line-height: 43px;
  font-size: 15px;
}
.sign-view{
  margin: 0  auto;
  width: 700px;
  height: 350px;
  border: 4px dashed #e9e9e9;
}
</style>