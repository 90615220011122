<template>
  <div style="display: flex;flex-wrap: wrap;">
    <div v-show="fileList.length > 0" class="file-box" v-viewer="options">
      <div
          class="file-item"
          v-for="(file,index) in fileList"
          :key="index"
      >
        <template v-if="file.status === 'uploading'">
          <div class="image-box">
            <a-icon type="loading" />
          </div>
          <span>上传中...</span>
        </template>
        <template v-else-if="file.status === 'error'">
          <div class="image-box">
            <a-icon type="file-exclamation" />
          </div>
          <span>上传失败</span>
        </template>
        <template v-else>
          <div class="image-box">
            <img
                v-if="file.fileType === 'image'"
                :src="file.full_url + IMG_ABBREVIATION"
                class="image"
                :data-source="file.full_url"
                style="width: 32px;height: 32px;background-color: #fff;"
                :alt="file.original_name"
            >
            <img @click="download(file)" v-else :src="file.fileType | fileTypeIcon" style="width: 32px;height: 32px;cursor: pointer;background-color: #fff;">
          </div>
          <!-- <a v-if="file.fileType === 'image'" @click="show">{{ file.original_name }}</a> -->
          <!--          <a v-if="file.fileType === 'image'" @click="show">{{file.original_name }}</a>-->
          <a v-if="file.fileType === 'image'" @click="show">{{ interceptUrl(file.original_name) }}</a>
          <a v-else @click="download(file)">{{ file.original_name }}</a>
        </template>
        <div class="remove-div" v-if="canUpload || isRemove">
          <a-icon class="remove-icon" type="close-circle" @click="removeFile(index)" />
        </div>
      </div>
    </div>
    <a-upload
        v-if="canUpload"
        name="file"
        :accept="accept"
        :show-upload-list="false"
        :multiple="true"
        :action="serverUrl"
        :headers="headers"
        :before-upload="beforeInUpload"
        :data="initData"
        @change="handleChange"
    >
      <a-button type="link" icon="paper-clip">添加附件</a-button>
    </a-upload>
    <a-modal
        :title="video.title"
        :footer="null"
        :visible="visible"
        :width="400"
        @cancel="closeVideo"
    >
      <video
          id="video"
          autoplay
          :src="video.src"
          controls
          width="100%"
          height="99%"
      ></video>
    </a-modal>
  </div>
</template>

<script>
import fileTypeIcon from '@/assets/fileTypeIcon'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
import { matchFileType, downloadFile, compressorImage } from '@/utils/utils'
import moment from 'moment'
import md5 from 'md5'
import enums from '@/utils/enums'
Viewer.setDefaults({
  url: 'data-source',
  filter (image) {
    const dataSource = image.getAttribute('data-source')
    if (dataSource === null || dataSource === undefined) {
      return false
    } else {
      return true
    }
  }
})
Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 9999
  }
})

export default {
  name: 'AttachmentList',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String]
    },
    canUpload: {
      type: Boolean,
      default: () => true
    },
    isRemove: {
      type: Boolean,
      default: () => false
    },
    accept: {
      type: String,
      default: () =>
          '.jpg,.x_t,.gbr,.jdp,.jpeg,.png,.gif,.bmp,.mp3,.mp4,.avi,.rmvb,.dwg,.dwt,.dws,dwf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf,.zip,.rar,.psd,.dxf,.txt,.igs,.iges,.stp,.step'
    }
  },
  data () {
    return {
      fileList: [],
      options: {
        toolbar: true,
        url: 'data-source'
      },
      headers: {
      },
      visible: false,
      video: {
        title: '查看视频',
        src: null
      },
      serverUrl: process.env.VUE_APP_API_BASE_URL + '/upload/file', // 这里写你要上传的图片服务器地址
      IMG_ABBREVIATION: enums.IMG.ABBREVIATION
    }
  },
  filters: {
    fileTypeIcon (fileType) {
      return fileTypeIcon[fileType]
    }
  },
  methods: {
    interceptUrl (x) {
      if (!x) {
        return '未命名.jpg'
      }
      if (x.length < 20) {
        return x
      }
      const t = x.substring(0, 10)
      const tt = x.substring(x.length - 10, x.length)
      return t + '......' + tt
    },
    initData (file) {
      const timestamp = moment().unix()
      const salt = process.env.VUE_APP_REQUEST_SALT
      const sign = md5(timestamp + salt)
      return {
        file,
        sign,
        timestamp,
      }
    },
    closeVideo () {
      this.visible = false
      const myVideo = document.getElementById('video')
      myVideo.pause()
    },
    download (file) {
      if (file.fileType === 'video') {
        this.video.title = file.original_name
        this.video.src = file.full_url
        this.visible = true
      } else {
        downloadFile(file.full_url, file.original_name)
      }
    },
    downloadFile,
    show (event) {
      const el = event.currentTarget.previousElementSibling.firstElementChild
      el.click()
    },
    handleChange (info) {
      if (info.file.status !== 'uploading') {
        this.beforeUpload()
      }
      if (info.file.response) {
        if (info.file.response.success) {
          this.uploadSuccess(info.file)
        } else {
          this.uploadError(info.file)
        }
      }
    },
    beforeInUpload (file, fileList) {
      if (fileList?.length > 10) {
        this.$message.error({ content: '单次上传附件最多支持选择10个，总共支持上传50个附件。', key: 'error' })
        return false
      }
      if (this.accept) {
        const accept = this.accept.split(',')
        const type = file.name.substring(file.name.lastIndexOf('.'))
        if (!accept.some(item => item === type.toLowerCase())) {
          this.$message.error({ content: '上传文件不支持' + type + '格式', key: 'file_type' })
          return false
        }
      }
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error({ content: '文件大小不能大于20M', key: 'file_upload' })
        return false
      } else {
        // const d = file.uid
        if (file.type.slice(0, 5) === 'image') {
          return new Promise((resolve, reject) => {
            compressorImage(file).then(res => {
              const tempFile = new File([res], res.name, { type: res.type })
              tempFile.uid = file.uid
              if (this.fileList.length < 50) {
                this.fileList.push({ uid: tempFile.uid, status: 'uploading' })
              } else {
                this.$message.error({ content: '最多上传50个附件', key: 'file_upload_num' })
                return false
              }
              this.$emit('input', this.fileList)
              this.$emit('change', this.fileList)
              resolve(tempFile)
            }).catch(err => {
              reject(err)
            })
          })
        } else {
          if (this.fileList.length < 50) {
            this.fileList.push({ uid: file.uid, status: 'uploading' })
          } else {
            this.$message.error({ content: '最多上传50个附件', key: 'file_upload_num' })
            return false
          }
          this.$emit('input', this.fileList)
          this.$emit('change', this.fileList)
        }
      }
    },
    // 图片上传前
    beforeUpload () {
      // 显示loading动画
      this.quillUpdateImg = true
    },
    // 图片上传成功
    uploadSuccess (file) {
      const { response, uid, size } = file
      let model = this.fileList.find(item => item.uid === uid)
      const index = this.fileList.findIndex(item => item.uid === uid)
      // res为图片服务器返回的数据
      // 如果上传成功
      if (response.success && response.data && response.data.full_url) {
        // res.data.full_url为服务器返回的图片地址
        const url = response.data.full_url
        const fileType = matchFileType(url)
        if (model) {
          model = { ...model, status: 'done', id: null, full_url: url, url: response.data.url, original_name: response.data.original_name, fileType: fileType, size: size }
          this.fileList.splice(index, 1, model)
        }
      } else {
        if (model) {
          model = { ...model, status: 'error' }
          this.fileList.splice(index, 1, model)
        }
        // this.$message.error('文件上传失败')
      }
      this.fileList.forEach(item => {
        if (!item.file_size) {
          item.file_size = item.size
        }
      })
      this.$emit('input', this.fileList)
      this.$emit('change', this.fileList)
      // loading动画消失
      this.quillUpdateImg = false
    },
    // 图片上传失败
    uploadError (file) {
      const { uid } = file
      let model = this.fileList.find(item => item.uid === uid)
      const index = this.fileList.findIndex(item => item.uid === uid)
      if (model) {
        model = { ...model, status: 'error' }
        this.fileList.splice(index, 1, model)
      }
      if (file.response) {
        this.$message.error(file.response.msg)
      }
      this.$emit('input', this.fileList)
      this.$emit('change', this.fileList)
      // loading动画消失
      this.quillUpdateImg = false
    },
    removeFile (index) {
      this.fileList.splice(index, 1)
      this.$emit('input', this.fileList)
      this.$emit('change', this.fileList)
    },
    initValue () {
      const attachments = []
      if (this.value) {
        let data = JSON.parse(JSON.stringify(this.value))
        if (typeof data === 'string') {
          data = JSON.parse(data)
        }
        data && data.map(x => {
          if (x.status === 'done' || !x.status) {
            x.fileType = matchFileType(x.url)
          }
          attachments.push(x)
        })
      }
      this.fileList = attachments
    }
  },
  mounted () {
    this.initValue()
  },
  watch: {
    value () {
      this.initValue()
    }
  }
}
</script>

<style lang="less" scoped>
.image {
  cursor: pointer;
}
.file-box {
  //display: flex;
  //flex-wrap: wrap;
  width: 100%;
  padding: .5em;
  background: #f3f3f3;
  border-radius: 5px;
}
.image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.file-item {
  display: flex;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  a {
    flex: 1;
    overflow: hidden;
    word-break: break-all;
  }
  span {
    flex: 1;
    overflow: hidden;
    word-break: break-all;
  }
  .remove-div {
    display: flex;
    width: 14px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  .remove-icon {
    display: none;
  }
}
.file-item:hover {
  .remove-icon {
    display: block;
  }
}

/deep/ .ant-modal-wrap{
  z-index: 99999;
}
/deep/ .ant-modal-body{
  padding: 5px;
}
/deep/ .ant-modal{
  padding-bottom: 0;
  margin: auto;
}
/deep/ .ant-modal-body{
  height: 550px;
  overflow: auto;
}
</style>
