<script>
import {Overlay} from "vant"
import Sign from "@/components/Sign/index.vue";
// import {rotateBase64Img} from "@/utils/utils";

export default {
  name: "SignOverlay",
  components: {Sign, Overlay},
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close(){
      this.visible = false
      this.$emit('close')
    },
    exportImg(data){
      if(!data.isEmpty){
        this.$emit('signImg',data.data)
        // rotateBase64Img(data.data,-90,(data)=>{
        //   this.$emit('signImg',(data))
        // })
        this.close()
      }
    },
  }
}
</script>

<template>
  <Overlay :show="visible" :lock-scroll="false">
    <div class="sign-view">
      <Sign @exportImg="exportImg" @quit="close" v-if="visible"></Sign>
    </div>
  </Overlay>
</template>

<style scoped lang="less">
.sign-view{
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>